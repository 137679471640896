export enum ApplicationStepName {
  YourName = 'Your Name',
  YourContact = 'Your Contact',
  AboutYou = 'About You',
  YourAddress = 'Your Address',
  YourIncome = 'Your Income',
  YourCreditReport = 'Your Credit Report',
  CreatingOffer = 'Creating Offer',
  HardOffer = 'Hard Offer',
  PayOffPlan = 'Pay Off Plan',
  PlanSent = 'Plan Sent',
  LoanAmountRange = 'Loan Amount Range',
  PreQualificationDecline = 'PreQualification Decline',
  CreditScoreRange = 'Credit Score Range',
  OfferAvailable = 'Offer Available',
  Location = 'Location',
  EmployersName = 'EmployerName',
  PayrollSystem = 'PayrollSystem',
  PayrollSuccessConnection = 'Payroll Success Connection',
  SecondEmployer = 'Second Employer',
  ArgyleLinkNotSupported = 'Argyle Link Is Not Supported',
  ConfirmLoan = 'Confirm Loan',
  OfferStatus = 'Offer Status',
  EmailNotificationAlert = 'Email Notification Alert',
  DocuSignSignature = 'DocuSignSignature',
  RepaymentMethod = 'Repayment Method',
  SetupDeduction = 'Setup Deduction',
  SetupViaPayroll = 'Setup Via Payroll',
  SetupViaHr = 'Setup Via HR',
  VerifyPayrollDeduction = 'Verify Payroll Deduction',
  FirstDeduction = 'First Deduction',
  FundsSent = 'Funds Sent',
  BankAccount = 'Bank Account',
  FileUpload = 'File Upload',
  NextSteps = 'Next Steps',
  Plaid = 'Plaid',
  PlaidCheckingAccounts = 'Plaid Checking Accounts',
  OutOfState = 'Out of State',
  Offer = 'Offer',
  PreQualification = 'PreQualification',
  YourBirthDate = 'BirthDate',
  YourPaySchedule = 'PaySchedule',
  YourEmployer = 'YourEmployer',
  VerifyDetailsIntro = 'VerifyDetailsIntro',
  YourTotalIncome = 'YourTotalIncome',
  Feedback = 'Feedback',
  Alloy = 'Alloy',
  YourGoal = 'YourGoal',
  Citizenship = 'Citizenship',
  OpenDDADone = 'Open DDA Done',
  CardApplied = 'Card Applied',
  Error = 'Error',
  // Financial Checkup
  YourPhoneNumber = 'Your Phone Number',
  YourEmail = 'Your Email',
  YourSavings = 'Your Savings',
  YourFinances = 'Your Finances',
  YourSSN = 'Your Social Security Number',
  HowItWorks = 'How It Works',
  MethodAuth = 'Method Auth',
  Analyzing = 'Analyzing',
  // Card
  CardInfo = 'Card Info',
  CardApplication = 'Your Card Application',
  CardNextSteps = 'Card Next Steps',
  XSellLoader = 'Loading',
  // Missed Payments
  MissedPayment = 'Missed Payment',
  MakeAPayment = 'Make A Payment',
  MissedPaymentReason = 'Missed Payment Reason',
  Authorization = 'Authorization',
  WaitingAuthorization = 'Waiting Authorization',
  MakingPayment = 'Making Payment',
  PaymentInitiated = 'Payment Initiated',
  PaymentFailed = 'Payment Failed',
}
