import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Button from 'components/Button';
import ShareButton from 'components/ShareButton';
import { useNavigate } from 'hooks/useNavigate';
import { RoutePath } from 'enums/Routes';
import { getApplicationData } from 'selectors/getApplicationData';
import { setCardData } from 'handlers/cardData';
import { getCardData } from 'selectors/getCardData';

import StatusButton from 'components/Common/Buttons/StatusButton';
import { ReactComponent as CheckIcon } from 'images/green-check.svg';
import { ConversionType, trackConversion } from 'utils/analytics';

import styles from './YourFinances.module.scss';

const PlanneryCardInformation = ({ cardTextLabel, short }: { cardTextLabel?: string; short?: boolean }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { cardApplied, cardReferralLink } = useSelector(getApplicationData).application!;

  const data = useSelector(getCardData);

  useEffect(() => {
    analytics.track('Card CTA Viewed', { applied: cardApplied });
    if (cardApplied) {
      dispatch(
        setCardData({
          applied: cardApplied,
          referralLink: cardReferralLink,
        }),
      );
    }
  }, []);

  const onClick = () => {
    navigate(RoutePath.CardInfo);
  };

  const onShare = () => {
    analytics.track('Card CTA Share Link Copied');
    trackConversion(ConversionType.CardShareSocialClick);
  };

  const shareButton = (
    <ShareButton className={styles.shareButton} onClick={onShare} url={data.referralLink}>
      Share and move to the front!
    </ShareButton>
  );

  return short ? (
    <>
      {data.applied && <StatusButton className={styles.statusButton} label="Applied" icon={<CheckIcon />} />}
      {data.applied && data.referralLink ? shareButton : <Button onClick={onClick}>See How</Button>}
    </>
  ) : (
    <div className={styles.planneryCardInfoContainer}>
      <p className={styles.sectionTitle}>Get the Plannery Card!</p>
      <ul>
        <li>5% back on professional expenses</li>
        <li>2% back on everything else</li>
        {cardTextLabel && <li>{cardTextLabel}</li>}
        <li>Uses AI to reduce your financial stress</li>
        <li>No annual or monthly fees</li>
      </ul>

      {data.applied && <StatusButton className={styles.statusButton} label="Applied" icon={<CheckIcon />} />}
      {data.applied && data.referralLink ? shareButton : <Button onClick={onClick}>Learn More</Button>}
    </div>
  );
};

export default PlanneryCardInformation;
