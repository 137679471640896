import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import { getCardData } from 'selectors/getCardData';
import { ReactComponent as CheckIcon } from 'images/check-icon-rounded.svg';
import Button from 'components/Button';
import { ButtonType } from 'components/Button/Button';
import FormNavigation from 'components/FormNavigation';
import useCurrentFlow from 'hooks/useCurrentFlow';
import { formatNumber } from 'utils/formatNumber';
import ShareModal from 'components/ShareModal/ShareModal';

import styles from './CardApplied.module.scss';

const SHARE_MESSAGE = 'I just signed up for Plannery, a new card just for healthcare professionals. Check it out!';

const CardApplied = ({ handleNext, navigationInfo }: any) => {
  const { isCardFlow, isFinancialCheckupFlow } = useCurrentFlow();
  const [showShareModal, setShowShareModal] = useState(false);

  const handleCloseModal = () => {
    handleNext();
  };

  const onShare = () => {
    setShowShareModal(true);
    analytics.track('Card CTA Share Button Clicked');
  };

  // const position = 1243; // TODO: replace with real position
  const { referralLink, waitListPosition } = useSelector(getCardData);

  return (
    <div>
      <FormNavigation {...navigationInfo} />
      <div className={styles.container}>
        <div className={styles.iconContainer}>
          <CheckIcon />
        </div>
        <div className={styles.title}>Thanks for applying!</div>
        <div className={styles.description}>
          You're {waitListPosition ? <span className={styles.bold}>{formatNumber(waitListPosition)}</span> : <></>} on
          the waitlist! We'll notify you about any important updates.
        </div>
        {referralLink && (
          <Button className={styles.shareButton} onClick={onShare}>
            Share and move to the front!
          </Button>
        )}
        {isFinancialCheckupFlow && (
          <Button type={ButtonType.Inverted} onClick={handleNext}>
            Back to Financial Checkup
          </Button>
        )}
        {isCardFlow && (
          <Button type={ButtonType.Inverted} onClick={handleNext}>
            Not Now
          </Button>
        )}
      </div>
      {showShareModal && (
        <ShareModal
          shareMessage={SHARE_MESSAGE}
          handleClose={handleCloseModal}
          referralLink={referralLink || 'https://planneryapp.com'}
        />
      )}
    </div>
  );
};

export default CardApplied;
